import { useEffect, useState } from "react";
import TableLoader from "./Loader/TableLoader";
import { useLocation } from "react-router-dom";
import { API_URL } from "../config";
import axios from "axios";
import CustomTemplate from "./CustomTemplate";

const DomainUsers = () => {
    const [showUser, setShowUser] = useState(false);
    const [userData, setUserData] = useState([]);
    const [selectedEmails, setSelectedEmails] = useState([]);
    const [templateData, setTemplateData] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState("");
    const [itemNum, setItemNum] = useState(1);
    const [isLoading, setLoading] = useState(true);
    const [totalPage, setTotalPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [templateId, setTemplateId] = useState(0);
    const [nestedVisibility, setNestedVisibility] = useState({});
    const [selectAll, setSelectAll] = useState(false);  // State to track Select All checkbox
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const domain = queryParams.get("domain");
    const [multiple, setMultiple] = useState(false)
    // Get domain user data
    const getDomainUser = async () => {
        setLoading(true);
        let apiUrl = `${API_URL}/get-domain-all-user/?domain=${domain}`;

        try {
            const response = await axios.get(`${apiUrl}`);
            if (currentPage) {
                setItemNum(currentPage * 10 - 9);
            }
            setUserData(response?.data?.data);
            setTotalPage(response?.data?.total_pages);
            setLoading(false);
            setShowUser(true);
        } catch (error) {
            setLoading(false);
            console.log(error.response ? error.response.data.message : "An error occurred");
        }
    };
    // Get template list
    const getTemplateList = async () => {
        setLoading(true);
        let apiUrl = `${API_URL}/templates/?page=${currentPage}&page_size=100`;

        try {
            const response = await axios.get(`${apiUrl}`);
            if (currentPage) {
                setItemNum(currentPage * 10 - 9);
            }
            setTemplateData(response?.data?.results);
            setTotalPage(response?.data?.total_pages);
        } catch (error) {
            setLoading(false);
            console.log(error.response ? error.response.data.message : "An error occurred");
        }
    };

    useEffect(() => {
        getDomainUser();
        getTemplateList();
    }, []);

    // Handle primary email checkbox click
    const handleEmailCheckboxClick = (userEmail, isChecked) => {
        setSelectedEmails((prevSelectedEmails) => {
            let updatedSelectedEmails = [...prevSelectedEmails];

            if (isChecked) {
                // Add the primary email and initialize its alias_emails array
                updatedSelectedEmails.push({
                    primaryEmail: userEmail,
                    // alias_emails: [] // Initially, primary email is its own alias
                });
            } else {
                // If unchecked, remove the primary email and its aliases
                updatedSelectedEmails = updatedSelectedEmails.filter((emailData) => emailData.primaryEmail !== userEmail);
            }

            return updatedSelectedEmails;
        });

        // Toggle visibility for the clicked email (for showing/hiding nested checkboxes)
        setNestedVisibility((prevState) => ({
            ...prevState,
            [userEmail]: isChecked, // Show or hide nested checkboxes
        }));
    };

    // Handle alias email checkbox click
    const handleAliasCheckboxClick = (primaryEmail, alias, isChecked) => {
        setSelectedEmails((prevSelectedEmails) => {
            let updatedSelectedEmails = [...prevSelectedEmails];

            // Find the primary email object in the array
            const primaryEmailIndex = updatedSelectedEmails.findIndex((emailData) => emailData.primaryEmail === primaryEmail);
            if (primaryEmailIndex !== -1) {
                let primaryEmailData = updatedSelectedEmails[primaryEmailIndex];

                if (isChecked) {
                    // Add alias to the alias_emails array of the primary email
                    if (!primaryEmailData.alias_emails.includes(alias)) {
                        primaryEmailData.alias_emails.push(alias);
                    }
                } else {
                    // Remove alias from the alias_emails array
                    primaryEmailData.alias_emails = primaryEmailData.alias_emails.filter((email) => email !== alias);
                }

                // Update the selected emails array with the modified primary email data
                updatedSelectedEmails[primaryEmailIndex] = primaryEmailData;
            }

            return updatedSelectedEmails;
        });
    };
    // Handle "Select All" button click
    const handleSelectAll = () => {
        setSelectAll((prevSelectAll) => {
            const newSelectAllState = !prevSelectAll;
    
            // Select all primary emails and their aliases
            setSelectedEmails((prevSelectedEmails) => {
                if (newSelectAllState) {
                    const allEmails = userData.map((item) => ({
                        // primaryEmail: item.user_email,
                        primaryEmail: item.user_email,
                        // alias_emails: item.aliases || [item.user_email], // Include the primary email as its own alias
                    }));
                    return allEmails;
                } else {
                    // Unselect all emails and clear alias selections
                    return [];
                }
            });
    
            // Expand or collapse all nested checkboxes (show all alias checkboxes)
            setNestedVisibility((prevState) => {
                const newVisibility = {};
                userData.forEach((item) => {
                    // Deselect all nested alias checkboxes and hide them
                    newVisibility[item] = newSelectAllState; // Show all nested checkboxes on select, hide on unselect
                    // newVisibility[item.user_email] = newSelectAllState; // Show all nested checkboxes on select, hide on unselect
                });
                return newVisibility;
            });
    
            return newSelectAllState;
        });
    };
    

    const handleCheckboxClick = (templateId) => {
        if (templateId === selectedTemplate) {
            setTemplateId(0); // Reset templateId to 0
            setSelectedTemplate(0); // Reset selectedTemplate to 0
          } else {
            setTemplateId(templateId); // Set templateId to the clicked template's ID
            setSelectedTemplate(templateId); // Set selectedTemplate to the clicked template's ID
          }
    };
    useEffect(()=>{
        if(selectedEmails.length < 1){
            setSelectAll(false)
        }
    },[selectedEmails])
    useEffect(() => {
        if (selectedEmails.length === userData.length) {
            // If all primary emails (and their aliases) are selected, set selectAll to true
            setSelectAll(true);
        } else {
            // Otherwise, set selectAll to false
            setSelectAll(false);
        }
    }, [selectedEmails, userData]);
    
    return (<>
        <div className="mainWrapper topbar">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-header">
                            <h3 className="mt-3">Domain users</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="mainWrapper domainUsers">
            <div className="container-fluid">
                
                <div className="row">
                    <div className="col-md-12">
                        <div className="commonForm"></div>
                        <div>
                            {showUser && userData && userData.length > 0 ? (
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="userOuter">
                                                    <h4>User List</h4>
                                                    <div className="selectAllWrapper form-check">
                                                        <span>
                                                        <input
                                                            type="checkbox" className="form-check-input"
                                                            checked={selectAll}
                                                            onChange={handleSelectAll}
                                                        />
                                                        </span>
                                                        <label>Select All</label>
                                                    </div>
                                                    <div className="users">
                                                        {userData.map((item) => (
                                                            <div key={item.user_email} className="checkBoxSection">
                                                                <div className="checkBoxSectionInner form-check">
                                                                    <span>
                                                                        <input
                                                                            type="checkbox" className="form-check-input"
                                                                            checked={selectedEmails.some((emailData) => emailData.primaryEmail === item.user_email)}
                                                                            onChange={(e) => handleEmailCheckboxClick(item.user_email, e.target.checked)}
                                                                        />
                                                                    </span>
                                                                    <span className="aliaseName">{item.user_email}
                                                                        {/* <span className="nameInner">{item.type === "alias" ?item.type : item.parent}</span> */}

                                                                        <span className="nameInner">
                                                                            <p>Type: <span>{item?.type ==="alias" ? "Alias":"Primary Email"}</span></p>
                                                                            {item?.type ==="alias" ? (<p>Parent : <span>{item.parent}</span></p>):"" } 
                                                                            {/* {item.user_email} */}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                {/* {item?.aliases.length  && nestedVisibility[item.user_email] && (
                                                                    <div className="innerCheckbox" id={item.user_email}>
                                                                        {item.aliases?.map((alias) => (
                                                                            alias != item.user_email ?
                                                                            (<div key={alias} className="checkBoxSection form-check">
                                                                                <p className="alias">Alias</p>
                                                                            <span>
                                                                                <input
                                                                                    type="checkbox" className="form-check-input"
                                                                                    checked={selectedEmails
                                                                                        .find((emailData) => emailData.primaryEmail === item.user_email)
                                                                                        ?.alias_emails.includes(alias)}
                                                                                    onChange={(e) =>
                                                                                        handleAliasCheckboxClick(item.user_email, alias, e.target.checked)
                                                                                    }
                                                                                />
                                                                            </span>
                                                                            <span className="aliaseName">{alias}</span>
                                                                        </div>) :""
                                                                            
                                                                        ))}
                                                                    </div>
                                                                )} */}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 templateListContainer">
                                                {templateData.length > 0 && (
                                                    <div className="template-section">
                                                        <h4>Template List</h4>
                                                        <div className="template-section-inner">
                                                            {templateData.map((item, index) => (
                                                                <div key={index} className="checkBoxSection form-check">
                                                                    <span>
                                                                        <input
                                                                            type="checkbox" className="form-check-input"
                                                                            checked={selectedTemplate === item.id}
                                                                            onChange={() => handleCheckboxClick(item.id)}
                                                                        />
                                                                    </span>
                                                                    <span className="aliaseName">{item.template_name}</span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 templateListContainer">
                                        <CustomTemplate templateId={templateId} userDetails={selectedEmails} multiple={true} domain={domain} />
                                    </div>
                                </div>
                            ) : (
                                !showUser ? <TableLoader /> : "No Record Found"
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default DomainUsers;
